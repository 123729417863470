import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';
import {environment} from "@env/environment";

interface RequestResult {
    status: number;
    error: boolean;
    data: any;
    additionalData: any;
}
interface RequestOptions {
    url: any;
    endpoint?: any;
    data?: any;
    additionalHeader?: any;
    additionalBatchData?: any;
    responseType?: any;
    requests?: any;
    withCredentials?: boolean;
}
@Injectable({
    providedIn: 'root',
})
export class GatewayService {
    constructor(private httpClient: HttpClient) {}

    private parseJSONResponse(data: { [x: string]: any }, filterNode: string | null) {
        if (filterNode != null) {
            if (data.hasOwnProperty(filterNode)) {
                data = data[filterNode];
            }
        }
        return data;
    }

    private _mergeUrl(options: RequestOptions): string {
        options = Object.assign(
            {
                endpoint: null,
                url: null,
            },
            options || {},
        );

        options = JSON.parse(JSON.stringify(options));

        if (options.endpoint != null && options.endpoint[0] == '/') {
            options.endpoint = options.endpoint.substr(1);
        }
        if (options.url != null && options.url[0] == '/') {
            options.url = options.url.substr(1);
        }

        return ((options.endpoint != null ? options.endpoint + '/' : '') + (options.url != null ? options.url : ''));
    }

    public head(options: RequestOptions): Promise<any> {
        options = Object.assign(
            {
                url: null,
                additionalHeader: {},
                responseType: 'json',
            },
            options || {},
        );
        return new Promise((resolve, reject) => {
            if (options.url == null) {
                reject();
                return;
            }

            this.httpClient
                .head(options.url, {
                    headers: new HttpHeaders(
                        Object.assign(
                            {
                                //            'Content-Type' : 'application/json'
                            },
                            options.additionalHeader || {},
                        ),
                    ),
                    responseType: options.responseType,
                    withCredentials: false,
                    observe: 'response' as const,
                })
                .subscribe({
                    next: resp => {
                        resolve(resp);
                    },
                    error: err => {
                        reject(err);
                    },
                });
        });
    }

    public get(options: RequestOptions): Promise<any> {
        options = Object.assign(
            {
                endpoint: environment.apiEndpoint,
                url: null,
                additionalHeader: {},
                responseType: 'json',
                withCredentials: false,
            },
            options || {},
        );
        return new Promise((resolve, reject) => {
            if (options.url == null) {
                reject();
                return;
            }

            let url = options.url;
            if (options.endpoint != null) {
                url = this._mergeUrl({
                    endpoint: options.endpoint,
                    url: options.url,
                });
            }

            this.httpClient
                .get(url, {
                    headers: new HttpHeaders(
                        Object.assign(
                            {
                                'Content-Type': 'application/' + options.responseType,
                            },
                            options.additionalHeader || {},
                        ),
                    ),
                    responseType: options.responseType,
                    withCredentials: options.withCredentials == true || options.endpoint != null,
                    observe: 'response' as const,
                })
                .pipe(
                    catchError((error: any) => {
                        let errorMessage = '';
                        if (error.error instanceof ErrorEvent) {
                            // client-side error
                            errorMessage = `Error: ${error.error.message}`;
                        } else {
                            // server-side error
                            errorMessage = error.message;
                        }
                        return throwError(() => {
                            return errorMessage;
                        });
                    }),
                )
                .toPromise()
                .then(
                    (response: any) => {
                        if (!response) {
                            reject('empty response');
                            return;
                        }
                        var respData = null;
                        if (response.headers && (response.headers.get('Content-Type') + '').toLowerCase().indexOf('json') > -1) {
                            try {
                                respData = response.body;
                                if (typeof respData === 'string') {
                                    respData = JSON.parse(respData);
                                }
                                respData = this.parseJSONResponse(respData, null);
                            } catch (err) {
                                console.log(err);
                            }
                        } else if (response.headers && (response.headers.get('Content-Type') + '').toLowerCase().indexOf('blob') > -1) {
                            respData = response.blob();
                        } else {
                            respData = response.body;
                        }
                        resolve(respData);
                    },
                    err => {
                        console.log(err);

                        reject(err);
                    },
                );
            /*
                .subscribe({
                    next: (resp: any) => {
                        var respData = null;
                        if (resp.headers && (resp.headers.get('Content-Type') + '').toLowerCase().indexOf('json') > -1) {
                            try {
                                respData = resp.body;
                                if (typeof respData === 'string') {
                                    respData = JSON.parse(respData);
                                }
                                respData = this.parseJSONResponse(respData, null);
                            } catch (err) {
                                console.log(err);
                            }
                        } else {
                            respData = resp.body;
                        }
                        resolve(respData);
                    },
                    error: e => {
                        reject(e);
                    },
                    complete: () => console.info('complete'),
                });
                */
        });
    }

    public delete(options: RequestOptions): Promise<any> {
        options = Object.assign(
            {
                endpoint: environment.apiEndpoint,
                url: null,
                additionalHeader: null,
                responseType: 'text',
            },
            options || {},
        );

        return new Promise((resolve, reject) => {
            if (options.url == null) {
                reject();
                return;
            }

            let url = options.url;
            if (options.endpoint != null) {
                url = this._mergeUrl({
                    endpoint: options.endpoint,
                    url: options.url,
                });
            }

            this.httpClient
                .delete(url, {
                    headers: new HttpHeaders(
                        Object.assign(
                            {
                                'Content-Type': 'application/json',
                            },
                            options.additionalHeader || {},
                        ),
                    ),
                    responseType: options.responseType,
                    withCredentials: options.endpoint != null,
                    observe: 'response' as const,
                })
                .subscribe({
                    next: resp => {
                        let respData = null;
                        if(resp.status == 204){
                            resolve({});
                        } else {
                            if (resp.headers && (resp.headers.get('Content-Type') + '').toLowerCase().indexOf('json') > -1) {
                                try {
                                    let bodyObj;
                                    if (typeof resp.body != 'object') {
                                        bodyObj = JSON.parse(resp.body);
                                    } else {
                                        bodyObj = resp.body;
                                    }
                                    respData = this.parseJSONResponse(bodyObj, null);
                                } catch (err) {
                                    console.log(err);
                                }
                            } else {
                                respData = resp.body;
                            }
                            resolve(respData);
                        }
                    },
                    error: err => {
                        console.log(err);
                        reject(err);
                    },
                });
        });
    }

    private _request(method: string, options: RequestOptions): Promise<any> {
        options = Object.assign(
            {
                endpoint: environment.apiEndpoint,
                data: {},
                url: null,
                additionalHeader: null,
                responseType: 'json',
                additionalBatchData: null,
            },
            options || {},
        );
        return new Promise((resolve, reject) => {
            if (options.url == null) {
                reject();
                return;
            }

            let targetURL = options.url;
            if (options.endpoint != null) {
                targetURL = this._mergeUrl({
                    endpoint: options.endpoint,
                    url: options.url,
                });
            }
            this.httpClient
                .request(method, targetURL, {
                    body: options.data,
                    headers: new HttpHeaders(
                        Object.assign(
                            (options.additionalHeader || {}).hasOwnProperty('enctype')
                                ? {}
                                : {
                                      'Content-Type': 'application/json',
                                  },
                            options.additionalHeader || {},
                        ),
                    ),
                    observe: 'response' as const,
                    responseType: options.responseType,
                    withCredentials: true,
                })
                .subscribe({
                    next: resp => {
                        let respData = null;
                        if(resp.status == 204){
                            resolve({});
                        } else {
                            if (resp.headers && (resp.headers.get('Content-Type') + '').toLowerCase().indexOf('json') > -1) {
                                try {
                                    let bodyObj;
                                    if (typeof resp.body != 'object') {
                                        bodyObj = JSON.parse(resp.body);
                                    } else {
                                        bodyObj = resp.body;
                                    }
                                    respData = this.parseJSONResponse(bodyObj, null);
                                } catch (err) {
                                    console.log(err);
                                }
                            } else {
                                respData = resp?.body;
                            }
                            resolve(respData);
                        }
                    },
                    error: err => {
                        reject(err);
                    },
                });
        });
    }

    public post(options: RequestOptions): Promise<any> {
        return this._request('post', options);
    }

    public getWithBody(options: RequestOptions): Promise<any> {
        return this._request('get', options);
    }

    public put(options: RequestOptions): Promise<any> {
        return this._request('put', options);
    }

    public patch(options: RequestOptions): Promise<any> {
        return this._request('patch', options);
    }

    public getDeploymentTime(): Promise<any> {
        /*
        return new Promise((resolve, reject) => {
            this.get({
                endpoint: null,
                url: 'assets/info.json',
            }).then(resp => {
                resolve(resp.buildTime);
            }, reject)
        });
        */
        return this.get({
            endpoint: null,
            url: 'assets/timestamp.json',
            //additionalHeader: {
            //    'Content-Type': 'application/text',
            //},
            //responseType: 'text',
        });
    }

    public getDevelopmentNotes(): Promise<any> {
        return this.get({
            endpoint: null,
            url: 'assets/whats-new.json',
        });
    }

    public getReleaseNotes(): Promise<any> {
        return this.get({
            endpoint: null,
            url: 'assets/release-notes.json',
        });
    }

    public getOauthTokenFromResponse(): Promise<string | null | undefined> {
        return new Promise((resolve, reject) => {
            this.httpClient
                .get('assets/release-notes.json', {
                    headers: new HttpHeaders(
                        Object.assign({
                            'Content-Type': 'application/json',
                        }),
                    ),
                    responseType: 'json',
                    withCredentials: false,
                    observe: 'response' as const,
                })
                .toPromise()
                .then(
                    (response: any) => {
                        resolve(response.headers.get('Authorization'));
                    },
                    err => {
                        resolve(null);
                    },
                );
        });
    }
}
