import {computed, effect, inject, Injectable, signal, Signal, WritableSignal} from '@angular/core';
import {UserModel} from '@shared/models/user.model';
import {environment} from "@env/environment.prod";

import {GatewayService} from "@shared/services/gateway.service";
import {endpoints} from "../../../endpoints/endpoints";
import {ERole} from "@shared/enums/role.enum";
import {createDataStore} from "@shared/data-store";

@Injectable({
	providedIn: 'root',
})
export class UserService {
	private gatewayService: GatewayService = inject(GatewayService);


	store = createDataStore<UserModel, 'id'>({
		primaryKey: 'id',
		initialItemValue: new UserModel(),
	});

	item = computed(() => this.store.item());
	user: WritableSignal<UserModel> = signal(new UserModel());
	//user = computed(() => this.item().value as UserModel);
	accessToken: WritableSignal<string|null> = signal(null);
	isLoggedIn: Signal<boolean> = computed(() => {
		return !!this.user()?.id && this.user()?.id > -1;
	});
	isAdmin: Signal<boolean> = computed(() => this.user()?.isAdmin);

	constructor() {
			effect(() => {
			if (this.accessToken()) {
				localStorage.setItem(environment.localStorageName + '.accessToken', JSON.stringify(this.accessToken()));
			} else {
				localStorage.removeItem(environment.localStorageName + '.accessToken');
			}

			if (this.user() && this.user()?.id && this.user()?.id > -1) {
				localStorage.setItem(environment.localStorageName + '.user', JSON.stringify(this.user()));
			} else {
				localStorage.removeItem(environment.localStorageName + '.user');
			}
		})
	}

	setUserData(): Promise<boolean> {
		return new Promise<boolean>(resolve => {
			if(!this.accessToken()){
				resolve(false);
			} else {
				if(this.isLoggedIn()){
					this.store.updateItem(this.user());
					resolve(true);
				} else {
					resolve(false);
				}
			}
		});
	}

	logoutUser(): Promise<any> {
		return new Promise<boolean>(resolve => {
			this.store.clear();
			this.user.set(new UserModel());
			this.accessToken.set(null);
			resolve(true);
		});
	}
}
