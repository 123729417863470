import { Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { IRootVariable, TBackgroundColor, TColor, TTextColor } from '@shared/interfaces';

@Injectable({
	providedIn: 'root',
})
export class AppStyles {
	// ⚠️ list auto generated via script (css-variables-generator.js)
	private static cssVariables: string[] = [
		'--menu-width',
		'--border-width-primary',
		'--border-width-secondary',
		'--header-height',
		'--header-survey-height',
		'--item-height',
		'--connection-height',
		'--icon-size',
		'--theme-duration-fast',
		'--theme-duration-standard',
		'--theme-duration-slow',
		'--no-space',
		'--space-1',
		'--space-2',
		'--space-3',
		'--space-4',
		'--space-5',
		'--space-6',
		'--space-7',
		'--space-8',
		'--space-9',
		'--space-10',
		'--space-11',
		'--page-margin',
		'--page-margin-sm',
		'--container-padding',
		'--container-padding-sm',
		'--item-margin',
		'--item-margin-sm',
		'--safe-area-top',
		'--safe-area-bottom',
		'--safe-area-left',
		'--safe-area-right',
		'--divider-color',
		'--divider-color-soft',
		'--divider-color-strong',
		'--border-primary-rounded',
		'--border-primary-rounded-0',
		'--border-primary-rounded-2-5',
		'--border-primary-rounded-4',
		'--border-primary-rounded-40',
		'--loading-background',
		'--loading-background-strong',
		'--mat-table-background-color',
		'--mat-table-accent-background-color',
		'--font-family',
		'--font-title-xxl-font-size',
		'--font-title-xl-font-size',
		'--font-title-l-font-size',
		'--font-title-m-font-size',
		'--font-title-s-font-size',
		'--font-title-xs-font-size',
		'--font-body-l-font-size',
		'--font-body-m-font-size',
		'--font-body-s-font-size',
		'--font-body-xs-font-size',
		'--font-body-xxs-font-size',
		'--background-primary',
		'--background-secondary',
		'--background-tertiary',
		'--background-quaternary',
		'--background-modal',
		'--color-primary',
		'--color-primary',
		'--error-color',
		'--warning-color',
		'--success-color',
		'--color-link',
		'--color-secondary',
		'--color-primary-light',
		'--color-text-primary',
		'--color-text-secondary',
		'--promotion-info',
		'--color-grey-50',
		'--color-grey-100',
		'--color-grey-200',
		'--color-grey-300',
		'--color-grey-400',
		'--color-grey-600',
		'--color-grey-700',
		'--color-grey-900',
		'--color-grey-black',
		'--color-grey-white',
		'--promotion',
	];

	private _styles: WritableSignal<IRootVariable> = signal({} as IRootVariable);

	styles: Signal<IRootVariable> = this._styles.asReadonly();

	constructor() {
		window.addEventListener('load', () => {
			this.set();
		});
	}

	set(): void {
		this._styles.set(
			AppStyles.cssVariables.reduce((acc: { [key: string]: string }, rootVar: string) => {
				acc[AppStyles.convertCssVarToCamelCase(rootVar)] = AppStyles.getRootValue(rootVar);
				return acc;
			}, {}) as unknown as IRootVariable,
		);
		AppStyles.initializeStaticStyles();
		// console.log('App styles', this._styles(), styles);
	}

	getBackgroundColor(backgroundColor?: TBackgroundColor): string {
		if (backgroundColor == 'backgroundPrimary') return this.styles().backgroundPrimary;
		else if (backgroundColor == 'backgroundSecondary') return this.styles().backgroundSecondary;
		else if (backgroundColor == 'backgroundTertiary') return this.styles().backgroundTertiary;
		else if (backgroundColor == 'backgroundQuaternary')
			return this.styles().backgroundQuaternary;
		else if (backgroundColor == 'backgroundModal') return this.styles().backgroundModal;
		else return backgroundColor ?? '';
	}

	getColor(color?: TColor): string {
		if (color == '500') return this.styles().color500;
		else if (color == '700') return this.styles().color700;
		else if (color == 'error') return this.styles().errorColor;
		else if (color == 'warning') return this.styles().warningColor;
		else if (color == 'success') return this.styles().successColor;
		else return color ?? '';
	}

	getTextColor(textColor: TTextColor): string {
		if (textColor == 'colorGrey50') return this.styles().colorGrey50;
		else if (textColor == 'colorGrey100') return this.styles().colorGrey100;
		else if (textColor == 'colorGrey200') return this.styles().colorGrey200;
		else if (textColor == 'colorGrey300') return this.styles().colorGrey300;
		else if (textColor == 'colorGrey400') return this.styles().colorGrey400;
		else if (textColor == 'colorGrey600') return this.styles().colorGrey600;
		else if (textColor == 'colorGrey700') return this.styles().colorGrey700;
		else if (textColor == 'colorGrey900') return this.styles().colorGrey900;
		else if (textColor == 'colorGreyBlack') return this.styles().colorGreyBlack;
		else if (textColor == 'colorGreyWhite') return this.styles().colorGreyWhite;
		else return textColor;
	}

	getNumberFromPixels(key: keyof IRootVariable): number {
		const pixels = this.styles()[key];
		if (typeof pixels == 'string') return Number(pixels.replace('px', ''));
		return 0;
	}

	getNumberFromTime(key: keyof IRootVariable): number {
		const pixels = this.styles()[key];
		if (typeof pixels == 'string') return Number(pixels.replace('ms', ''));
		return 0;
	}

	toOpacity(key?: keyof IRootVariable, color?: string, alpha?: number): string {
		if (key) color = this.styles()[key];

		if (!color) return '';

		let r, g, b;
		if (color.includes('rgba')) {
			const split = color.split('rgba(')[1];
			const values = split.split(',');
			r = Number(values[0]);
			g = Number(values[1]);
			b = Number(values[2]);
		} else {
			color = color.toUpperCase();
			r = parseInt(color.slice(1, 3), 16);
			g = parseInt(color.slice(3, 5), 16);
			b = parseInt(color.slice(5, 7), 16);
		}

		return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
	}

	toRGB(key: keyof IRootVariable): { r: number; g: number; b: number } {
		const color = this.styles()[key];
		return this.hexToRgb(color);
	}

	getTextColorForBackground(color: string): string {
		const { r, g, b } = this.isRgbFormat(color)
			? this.getRgbValues(color)
			: this.hexToRgb(color);
		return this.luminance(r, g, b) > 0.179 ? 'black' : 'white';
	}

	private getRgbValues(rgb: string) {
		const regex = /^rgb\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)$/;
		const match = regex.exec(rgb);
		return {
			r: match ? parseInt(match[1], 10) : 0,
			g: match ? parseInt(match[2], 10) : 0,
			b: match ? parseInt(match[3], 10) : 0,
		};
	}

	private isRgbFormat(color: string) {
		// Regular expression to match the RGB format
		const regex = /^rgb\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)$/;
		return regex.test(color);
	}

	private hexToRgb(hex: string): { r: number; g: number; b: number } {
		let parsedHex = hex.startsWith('#') ? hex.slice(1) : hex;
		if (parsedHex.length === 3) {
			parsedHex = [...parsedHex].map(x => x + x).join('');
		}
		const rgb = parseInt(parsedHex, 16);
		const r = (rgb >> 16) & 255;
		const g = (rgb >> 8) & 255;
		const b = rgb & 255;
		return { r, g, b };
	}

	private luminance(r: number, g: number, b: number): number {
		const a = [r, g, b].map(v => {
			v /= 255;
			return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
		});
		return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
	}

	static convertCssVarToCamelCase(cssVar: string): string {
		// Remove the initial two dashes
		const cleanVar = cssVar.substring(2);

		// Split the string into words using hyphen as separator
		const words = cleanVar.split('-');

		// Convert to camelCase: leave the first word as is, capitalize subsequent words
		return words
			.map((word, index) => {
				return index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1);
			})
			.join('');
	}

	private static getRootValue(rootVar: string): string {
		const rootStyles = getComputedStyle(document.documentElement);
		const value: string = rootStyles.getPropertyValue(rootVar).trim();
		// Convert the string to a number (in seconds), then to milliseconds
		if (value.includes('s') && !value.includes('ms')) {
			const milliseconds = parseFloat(value) * 1000;
			return milliseconds.toString() + 'ms';
		}
		return value;
	}

	public static get staticStyles(): IRootVariable {
		return this.cssVariables.reduce((acc: { [key: string]: string }, rootVar: string) => {
			acc[this.convertCssVarToCamelCase(rootVar)] = this.getRootValue(rootVar);
			return acc;
		}, {}) as unknown as IRootVariable;
	}

	public static initializeStaticStyles(): void {
		styles = AppStyles.staticStyles;
	}
}

export let styles: IRootVariable = AppStyles.staticStyles;
