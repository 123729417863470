export const endpoints = {
    auth: {
        login: 'Sellers/login?include=user'
    },
    overview: {
        all: 'ReturnTrucks',
        byId: 'ReturnTrucks/{truckId}',
        create: 'ReturnTrucks',
        sendEmail: 'ReturnTrucks/send-email'
    },
    images: {
        upload: 'Images/return-trucks/{returnTruckId}/uploads',
        byId: 'Images/{imageId}'
    },
    sellers: {
        all: 'Sellers/list'
    },
    placeholder: '---- FOR STARTER PURPOSES ONLY ----'
}