import {IUser, IUserDto} from '@shared/interfaces/user.interface';
import {GenericModel} from '@shared/models/generic.model';

export class UserModel extends GenericModel<IUser> implements IUser {
	challenges: any;
	company: string;
	credentials: any;
	email: string;
	emailVerified: boolean;
	enableNotifications: boolean;
	firstName: string;
	id: number;
	isAdmin: boolean;
	lastName: string;
	phone: string;
	realm: any;
	status: any;
	superUser: string;
	userName: string;

	constructor(user?: Partial<IUserDto>) {
		super(user);
		this.id = user?.id ?? -1;
		this.challenges = user?.challenges;
		this.company = user?.company ?? '';
		this.credentials = user?.credentials;
		this.email = user?.email ?? '';
		this.emailVerified = user?.emailVerified ?? false;
		this.enableNotifications = user?.enableNotifications ?? false;
		this.firstName = user?.firstName ?? '';
		this.isAdmin = user?.isAdmin ?? false;
		this.lastName = user?.lastName ?? '';
		this.phone = user?.phone ?? '';
		this.realm = user?.realm;
		this.status = user?.status;
		this.superUser = user?.superUser ?? '';
		this.userName = user?.userName ?? '';
	}
}
