import {computed, effect, inject, Injectable, Signal, signal, WritableSignal,} from '@angular/core';
import {Capacitor} from '@capacitor/core';
import {toSignal} from '@angular/core/rxjs-interop';
import {debounceTime, fromEvent, map, startWith} from 'rxjs';
import {AppStyles} from '@shared/services/app-styles.service';

@Injectable({
	providedIn: 'root',
})
export class AppService {
	private _loading: WritableSignal<boolean> = signal(true);
	private _error: WritableSignal<boolean> = signal(false);
	private _menuState: WritableSignal<boolean> = signal(false);
	private _iOS: WritableSignal<boolean> = signal(Capacitor.getPlatform() === 'ios');
	private _android: WritableSignal<boolean> = signal(Capacitor.getPlatform() === 'android');
	private _isNativePlatform: WritableSignal<boolean> = signal(Capacitor.isNativePlatform());
	private _isDesktop = toSignal(
		fromEvent(window, 'resize').pipe(
			startWith(() => window.innerWidth > 800),
			debounceTime(50),
			map(() => window.innerWidth > 800),
		),
	);
	private _isLgScreen = toSignal(
		fromEvent(window, 'resize').pipe(
			startWith(() => window.innerWidth > 1536), // same as xl tailwind
			debounceTime(50),
			map(() => window.innerWidth > 1536),
		),
	);

	loading: Signal<boolean> = this._loading.asReadonly();
	error: Signal<boolean> = this._error.asReadonly();
	menuState: Signal<boolean> = this._menuState.asReadonly();
	iOS: Signal<boolean> = this._iOS.asReadonly();
	android: Signal<boolean> = this._android.asReadonly();
	isNativePlatform: Signal<boolean> = this._isNativePlatform.asReadonly();
	isMobile = computed(() => this._isNativePlatform() || !this._isDesktop());
	isLgScreen = computed(() => this._isLgScreen());


	styles = inject(AppStyles).styles;

	constructor() {
		effect(() => {
			if (this.isMobile()) document.body.classList.add('is-mobile');
			else document.body.classList.remove('is-mobile');
		});
	}

	startLoading(): void {
		this._loading.set(true);
	}

	stopLoading(): void {
		this._loading.set(false);
	}

	hasError(): void {
		this._error.set(true);
	}

	clearError(): void {
		this._error.set(false);
	}

	triggerMenu() {
		this._menuState.set(!this._menuState());
	}
}
