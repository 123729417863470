import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { UserService } from '@shared/services/user.service';
import { AppService } from '@shared/services/app.service';
import { filter } from 'rxjs';
import { map } from 'rxjs/operators';
import { toObservable } from '@angular/core/rxjs-interop';
import { AppRouteService } from '@shared/services/app-route.service';

export const AuthGuard = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
	const userData = inject(UserService);
	const router = inject(Router);
	const appService: AppService = inject(AppService);
	const appRouteService: AppRouteService = inject(AppRouteService);

	return toObservable(appService.loading).pipe(
		filter(loading => !loading),
		map(loading => {
			if (userData.isLoggedIn()) return true;
			router.navigate([appRouteService.routeAccess.login.routeConfig.path]);
			return false;
		}),
	);
};
