import { effect, inject, Signal, signal } from '@angular/core';
import {
	BehaviorSubject,
	catchError,
	delay,
	filter,
	lastValueFrom,
	Observable,
	of,
	skip,
	switchMap,
	tap,
} from 'rxjs';

import { map } from 'rxjs/operators';
import {
	ERROR_STATE,
	ICrudApi,
	IDataStoreConfig,
	IRefreshOptions,
	IRefreshSource,
	IStateReducer,
	LOADING_SILENT_STATE,
	LOADING_STATE,
	TDataState,
} from '@shared/data-store/interfaces';
import { toSignal } from '@angular/core/rxjs-interop';
import { translations } from '@shared/utils/translations';
import { IDataStoreApi } from '@shared/data-store/interfaces/data-store-api';
import { IResponseItem } from '@shared/interfaces/response-item.interface';


export function createDataStore<T, PrimaryKey extends keyof T, Options = any>(
	config: IDataStoreConfig<T, PrimaryKey>,
	crudApi?: ICrudApi<T, PrimaryKey, Options>,
	computed?: (value: T[]) => T[],
): DataStore<T, PrimaryKey, Options> {
	return new DataStore<T, PrimaryKey, Options>(config, crudApi, computed);
}

class DataStore<T, PrimaryKey extends keyof T, Options = any> {
	private _refresh: BehaviorSubject<IRefreshOptions<T[PrimaryKey]>> = new BehaviorSubject<
		IRefreshOptions<T[PrimaryKey]>
	>({});
	private _refreshItem: BehaviorSubject<IRefreshOptions<T[PrimaryKey]>> = new BehaviorSubject<
		IRefreshOptions<T[PrimaryKey]>
	>({});

	private _items: Signal<IRefreshSource<T[], T[PrimaryKey]>> = toSignal(
		this._refresh.pipe(
			skip(1),
			filter(() => !!this.crudApi?.get?.execute),
			tap(options => this._updateItems({ state: this.getStateOnRefresh(options) })),
			switchMap(options => this.fetchItems(options)),
			tap(options => this.completeItems(options)),
		),
		{ initialValue: { value: this.config.initialItemsValue ?? [] } },
	);
	private _item: Signal<IRefreshSource<T | undefined, T[PrimaryKey]>> = toSignal(
		this._refreshItem.pipe(
			skip(1),
			filter(() => !!this.crudApi?.getById?.execute),
			tap(options => this._updateItem({ state: this.getStateOnRefresh(options) })),
			switchMap(options => this.fetchItem(options)),
			tap(() => this.completeItem()),
		),
		{ initialValue: { value: this.config.initialItemValue ?? undefined } },
	);

	// reducers
	private _itemsReducer = signal(this.itemsInitialValue());
	private _itemReducer = signal(this.itemInitialValue());
	private _itemLoading = signal<string | 'loading'>('');

	// public
	items: Signal<IStateReducer<T[]>> = this._itemsReducer.asReadonly();
	item: Signal<IStateReducer<T | undefined>> = this._itemReducer.asReadonly();
	itemLoading: Signal<string | 'loading'> = this._itemLoading.asReadonly();

	readonly api: IDataStoreApi<T, Options> = {
		create: (item: T, options?: Options) => this._create(item, options),
		update: (item: T) => this._update(item),
		delete: (item: T) => this._delete(item),
	};

	constructor(
		private config: IDataStoreConfig<T, PrimaryKey>,
		private crudApi?: ICrudApi<T, PrimaryKey, Options>,
		private computed?: (value: T[]) => T[],
	) {
		effect(
			() => {
				this._updateItems(this._items());
			},
			{
				allowSignalWrites: true,
			},
		);

		effect(
			() => {
				this._updateItem(this._item());
			},
			{
				allowSignalWrites: true,
			},
		);
	}

	refreshItems(options?: IRefreshOptions<T[PrimaryKey]>) {
		if (options?.forceRefresh || this.isDataExpired(this._itemsReducer().lastRefreshed)) {
			this._refresh.next(options ?? {});
		}
	}

	refreshItem(options: IRefreshOptions<T[PrimaryKey]>) {
		if (options?.forceRefresh || this.isDataExpired(this._itemReducer().lastRefreshed)) {
			this._refreshItem.next(options);
		}
	}

	updateItems(updated: T[]) {
		this._updateItems({ value: updated });
	}

	updateItemsState(state: TDataState | undefined) {
		this._updateItems({ state: state });
	}

	updateItem(updated: Partial<T>, options?: { mutate: boolean }) {
		const currentInstance = this.item().value ?? {};
		const mutate = options?.mutate ?? false;

		// - If mutate is true (default), it mutates the existing object and then updates the item.
		// - If mutate is false, it creates a new object, assigns the updated properties, and updates
		//   the item with the new object to ensure change detection.
		if (mutate) {
			Object.assign(currentInstance, updated);
		} else {
			const newInstance = Object.create(Object.getPrototypeOf(currentInstance));

			// Copy all existing properties from the current instance to the new instance
			Object.assign(newInstance, currentInstance, updated);

			// Update the item with the new instance to trigger change detection
			this._updateItem({ value: newInstance });
		}
	}

	updateItemState(state: TDataState | undefined) {
		this._updateItem({ state: state });
	}

	clear() {
		this.clearItems();
		this.clearItem();
	}

	clearItems() {
		this._updateItems(this.itemsInitialValue());
	}

	clearItem() {
		this._updateItem(this.itemInitialValue());
	}

	private async _create(item: T, options?: Options): Promise<IResponseItem<T>> {
		if (!this.crudApi?.create?.execute) return {};
		this._itemLoading.set('loading');
		let response = await lastValueFrom(
			this.crudApi.create?.execute(item, options).pipe(delay(250)),
		);

		if (response.item) {
			this.updateItems([...this.items().value, response.item]);
		}

		this._itemLoading.set('');
		return response;
	}

	private async _update(item: T): Promise<IResponseItem<T>> {
		if (!this.crudApi?.update) return {};
		this._itemLoading.set((item[this.config.primaryKey] ?? '').toString());
		let response = await lastValueFrom(this.crudApi.update.execute(item).pipe(delay(250)));

		if (response.item) {
			Object.assign(item ?? {}, response.item);
		}

		this._itemLoading.set('');
		return response;
	}

	private async _delete(item: T): Promise<IResponseItem<T>> {
		if (!this.crudApi?.delete) return {};

		let response: IResponseItem<T>;

		const confirmationOptions = this.crudApi.delete.confirmationOptions;
		if (confirmationOptions) {
			const key: keyof T = confirmationOptions.title as keyof T;
			let title: string = item[key] ? item[key]!.toString() : '';
			if (!title) title = confirmationOptions.title?.toString() ?? '';
			if (!title) title = translations.global.delete;

			response = await lastValueFrom(
				this.crudApi!.delete!.execute(item[this.config.primaryKey]).pipe(
					delay(250),
				),
			)
		} else {
			this._itemLoading.set((item[this.config.primaryKey] ?? '').toString());
			response = await lastValueFrom(
				this.crudApi.delete.execute(item[this.config.primaryKey]).pipe(delay(250)),
			);
			this._itemLoading.set('');
		}

		if (response.success) {
			this._updateItems({
				value: [
					...this.items().value.filter(
						c => c[this.config.primaryKey] !== item[this.config.primaryKey],
					),
				],
			});
		}

		return response;
	}

	private _updateItems(updated: Partial<IStateReducer<T[]>>) {
		this._itemsReducer.update(current => ({
			...current,
			...updated,
		}));
	}

	private _updateItem(updated: Partial<IStateReducer<T | undefined>>) {
		this._itemReducer.update(current => ({
			...current,
			...updated,
		}));
	}

	private getStateOnRefresh(
		options: IRefreshOptions | IRefreshOptions<T[PrimaryKey]>,
	): TDataState {
		if (options.loadingSilent) return LOADING_SILENT_STATE;
		else return LOADING_STATE;
	}

	private fetchItems(
		options: IRefreshOptions<T[PrimaryKey]>,
	): Observable<IRefreshSource<T[], T[PrimaryKey]>> {
		return this.crudApi!.get!.execute(options.id).pipe(
			delay(250),
			map(item => ({ value: item, options: options })),
			catchError(() => this.handleItemsError()),
		);
	}

	private fetchItem(
		options: IRefreshOptions<T[PrimaryKey]>,
	): Observable<IRefreshSource<T | undefined, T[PrimaryKey]>> {
		if (!options.id) return of({ value: undefined }).pipe(delay(350));

		const item: T | undefined = this.items().value.find(
			n => n[this.config.primaryKey] == options.id,
		);

		if (!options.loadingSilent && item) {
			return of({ value: item });
		} else {
			return this.crudApi!.getById!.execute(options.id).pipe(
				map(item => ({ value: item, options: options })),
				catchError(() => this.handleItemError()),
			);
		}
	}

	private handleItemsError(): Observable<IRefreshSource<T[], T[PrimaryKey]>> {
		this._updateItems({ callAttempts: this.items().callAttempts + 1 });

		// send error
		if (!this.items().value.length) {
			this._updateItems({ state: ERROR_STATE });
			if (this.items().callAttempts > 1 && this.crudApi?.get?.onError) {
				this.crudApi.get?.onError();
			}
		} else if (this.crudApi?.get?.onError) {
			this.crudApi.get?.onError();
		}

		return of({ value: this.items().value });
	}

	private handleItemError(): Observable<IRefreshSource<T | undefined, T[PrimaryKey]>> {
		this._updateItem({ callAttempts: this.item().callAttempts + 1 });

		// send error
		if (!this.item().value) {
			this._updateItem({ state: ERROR_STATE });
			if (this.item().callAttempts > 1 && this.crudApi?.getById?.onError) {
				this.crudApi.getById?.onError();
			}
		} else if (this.crudApi?.getById?.onError) {
			this.crudApi.getById?.onError();
		}

		const item = this.item().value;
		let data = this.config.initialItemValue;
		if (item && item[this.config.primaryKey] === this._refreshItem.getValue().id) {
			data = this.item().value;
		}
		return of({ value: data }).pipe(delay(250));
	}

	private completeItems(state: IRefreshSource<T[], T[PrimaryKey]>): void {
		this._updateItems({
			state: this.items().state !== ERROR_STATE ? '' : this.items().state,
			value: this.getItemsValue(state),
			callAttempts: this.items().state === ERROR_STATE ? this.items().callAttempts : 0,
			lastRefreshed: Date.now(),
		});
		setTimeout(() => {
			if (!this.items().state && this.crudApi?.get?.onSuccess) this.crudApi.get?.onSuccess();
		});
	}

	private completeItem(): void {
		this._updateItem({
			state: this.item().state !== ERROR_STATE ? '' : this.item().state,
			callAttempts: this.item().state === ERROR_STATE ? this.item().callAttempts : 0,
			lastRefreshed: Date.now(),
		});
		setTimeout(() => {
			if (!this.item().state && this.crudApi?.getById?.onSuccess) {
				this.crudApi.getById.onSuccess();
			}
		});
	}

	private getItemsValue(state: IRefreshSource<T[], T[PrimaryKey]>) {
		return this.computed ? this.computed(state.value) : state.value;
	}

	private itemsInitialValue(): IStateReducer<T[]> {
		return {
			state: '',
			value: this.config.initialItemsValue ?? [],
			callAttempts: 0,
			lastRefreshed: undefined,
		};
	}

	private itemInitialValue(): IStateReducer<T | undefined> {
		return {
			state: '',
			value: this.config.initialItemValue,
			callAttempts: 0,
			lastRefreshed: undefined,
		};
	}

	private isDataExpired(lastRefreshed: number | undefined): boolean {
		if (!lastRefreshed) return true;

		const timeToCheck: number = 7 * 1000; // 7 seconds
		const currentTime: number = Date.now();
		return currentTime - lastRefreshed > timeToCheck;
	}
}
